import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../api/api";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";

import { formataStatus, formataTipoSolicitacao, formataHora } from '../../utils/formatadores'

import ViewImageModalTarefa from '../../components/ViewImageTarefaModal' 
import BaseLayout from "../../layout/BaseLayout";

import Tarefa from "../../components/tarefa/Tarefa";
import EditModalTarefas from "../../components/EditModalTarefas";

import icones from "../../assets/icons/icones";

import './styles.css'
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";

export default function Tarefas(){

  const navigate = useNavigate()

  const [selectStatus, setSelectStatus] = useState('Pendente');
  const [responsavel, setResponsavel] = useState('Todos');
  const [cliente, setCliente] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState('Todos');

  
  const [loading, setLoading] = useState(true)

  const [tarefas, setTarefas] = useState([])

  const [openImageModal, setOpenImageModal] = useState(false);
  const [idTarefa, setIdTarefa] = useState('')

  // informações para editar a tarefa
  const [idEditaTarefa, setIdEditaTarefa] = useState(null)
  const [loginEditaTarefa, setLoginEditaTarefa] = useState(null)
  const [descEditaTarefa, setDescEditaTarefa] = useState(null)
  const [codiPessoaEditaTarefa, setCodiPessoaEditaTarefa] = useState(null)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [nomeClienteEditaTarefa, setNomeClienteEditaTarefa] = useState(null)


  function openSidebar(){
    const event = new CustomEvent('openSidebar');
    window.dispatchEvent(event);
  }

  async function loadTarefas(){
    try {
      const response = await api.get(`/consulta/tarefas/${localStorage.getItem("suporte_usuario")}`);
      setTarefas(response.data.dados);
    }catch (error) {
      console.log(error)
    }finally {
      setLoading(false);
    }
  };

  const BuscarClientes = async () => {
    try {
      const response = await api.get(`/consulta/cliente/*|${localStorage.getItem("suporte_usuario")}`);
      setCliente(response.data.dados);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err)
    }
  };

  function verificaEquipe() {
      const usuario = localStorage.getItem("suporte_usuario");
      return usuario === "andre" || usuario === "samuel" || usuario === "bento" || usuario === "rodimilson";
  }
    
    useEffect(()=>{
      loadTarefas()
    }, [tarefas])

    useEffect(()=>{
      BuscarClientes()
    },[])

    const FinalizarTarefa = async (id) => {  
      if (window.confirm("Deseja realmente marcar esta tarefa como concluída?")) {

        try {
          let info = {
            nume_lanca: id,
            login: localStorage.getItem("suporte_usuario"),
            desc_statu: 3
          }

          await api.post(`/atualiza/tarefas`, info);
          toast.success("Tarefa finalizada com sucesso."); 

          const response = await api.get(`/consulta/tarefas/${localStorage.getItem("suporte_usuario")}`);
          setTarefas(response.data.dados)
          
        } catch (err) {
          toast.error("Erro ao concluir a tarefa.");
          console.error("Erro ao concluir a tarefa:", err);
        }
      } else {
        return;
      }
    };
  
    const ExcluiTarefa = async (id) => {
        if (window.confirm("Deseja realmente EXCLUIR essa tarefa?")) {
          try {

            let info = {
              LOGIN: localStorage.getItem("suporte_usuario"),
              nume_lanca: id
            }

            await api.post(`/delete/tarefas`, info);
            toast.success("Tarefa excluida com sucesso")

            const response = await api.get(`/consulta/tarefas/${localStorage.getItem("suporte_usuario")}`);
            setTarefas(response.data.dados)
          } catch (err) {
            console.error("Erro ao excluir a tarefa:", err);
            toast.error("Erro ao excluir a tarefa.")
          }
        } else {
          return;
        }
    };

    const handleSelectChange = (e) => {
        setClienteSelecionado(e.target.value);
    };

    function abrirModalImagem(id){
      setIdTarefa(id)
      setOpenImageModal(true)
    }

    function fecharModalImagem(){
      setOpenImageModal(false)
    }

    const customStyles = {
        rows: {
          style: {
            minHeight: '50px',
          },
        },
        headCells: {
          style: {
            paddingLeft: '8px', 
            paddingRight: '8px',
            fontWeight: 'bold',
            fontSize: '16px'
          },
        },
        cells: {
          style: {
            paddingLeft: '8px', 
            paddingRight: '8px',
            fontSize: '20px',
          },
        },
    };

    const conditionalRowStyles = [
      {
        when: row => row.status === "PENDENTE",
        style: {
          backgroundColor: "#FFB3B2"
        }
      },
      {
        when: row => row.status === "ANDAMENTO",
        style: {
          backgroundColor: "#ade4f0"
        }
      },
      {
        when: row => row.status === "TESTANDO",
        style: {
          backgroundColor: "#60a5fa"
        }
      },
      {
        when: row => row.status === "CONCLUÍDO",
        style: {
          backgroundColor: "#BFFFC8"
        }
      }
    ];

    const columns = [
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><strong>ID</strong></span>,
          selector: row => row.id,
          width: '60px',
          sortable: true,
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiUser  />Cliente</span>,
          selector: row => row.cliente,
          width: '180px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiPlus />Criado por</span>,
          selector: row => row.login,
          width: '110px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.RiCustomerService2Fill />Responsável</span>,
          selector: row => row.nome_respo,
          width: '180px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiCalendar />Criada em</span>,
          selector: row => row.data,
          // width: '180px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiClipboard />Solicitação</span>,
          selector: row => row.solicitacao,
          // width: '150px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiClock />Prazo</span>,
          selector: row => row.prazo,
          // width: '180px',
          sortable: true,
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiCheckSquare />Status</span>,
          selector: row => row.status,
          width: '110px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiSettings />Ações</span>,
          selector: row => row.acoes,
          // width: '150px'
        },
    ];

    function handleOpenModal(nome_cliente, login, nume_lanca, desc_lanca, codi_pesso){
      setNomeClienteEditaTarefa(nome_cliente)
      setOpenEditModal(true)
      setIdEditaTarefa(nume_lanca)
      setLoginEditaTarefa(login)
      setDescEditaTarefa(desc_lanca)
      setCodiPessoaEditaTarefa(codi_pesso)
      
    }

    function handleCloseModal(){
      setIdEditaTarefa(null)
      setOpenEditModal(false)
    }
      
    let dadosFiltrados = [];

    if (tarefas.length > 0) {
        dadosFiltrados = tarefas.filter((tarefa) => {
          // Filtro de status
          const statusFilter =
            (selectStatus === "Finalizada"  && tarefa.desc_statu ===   "3")  ||
            (selectStatus === "Testando"    && tarefa.desc_statu ===   "2")  ||
            (selectStatus === "Andamento"   && tarefa.desc_statu ===   "1")  ||
            (selectStatus === "Pendente"    && tarefa.desc_statu ===   "0")  ||
             selectStatus === "Todas";
      
          // Filtro de responsável
          const userFilter = responsavel === "Todos" || tarefa.nome_respo === responsavel;
      
          // Filtro de nome do responsável
          const nomeRespoFilter = responsavel === "Todos" || tarefa.nome_respo.toLowerCase().includes(responsavel.toLowerCase());
      
          // Filtro de nome do cliente
          const nomeCliente = clienteSelecionado === 'Todos' || tarefa.nome_conta.toUpperCase().includes(clienteSelecionado.toUpperCase());
      
          return statusFilter && userFilter && nomeRespoFilter && nomeCliente;
      
        }).map((tarefa) => ({
          id: Number(tarefa.nume_lanca),
          cliente: tarefa.nome_conta,
          login: tarefa.login,
          nome_respo: tarefa.nome_respo,
          solicitacao: formataTipoSolicitacao(tarefa.tipo_lanca),
          data: tarefa.data_hora.substring(0, 16),
          prazo: `${tarefa.data_prazo} - ${formataHora(tarefa.hora_prazo)}`,
          status: formataStatus(tarefa.desc_statu),
          acoes: (
            <div style={{ display: "flex", gap: ".25rem" }}>
             {verificaEquipe() && (
               <button className="botao-tabela-excluir" onClick={() => ExcluiTarefa(tarefa.nume_lanca)}>
               <icones.FiTrash2 size={20} />
             </button>
             )}
              <button className="botao-tabela-editar" onClick={() => 
                handleOpenModal(tarefa.nome_conta, tarefa.login, tarefa.nume_lanca, tarefa.desc_lanca, tarefa.codi_pesso)}>
                <icones.HiOutlinePencilAlt size={20} />
              </button>
              {tarefa.desc_statu !== "3" &&  (
                <button className="botao-tabela-finalizar" onClick={() => FinalizarTarefa(tarefa.nume_lanca)}>
                  <icones.FiCheck size={20} />
                </button>
              )}
              {tarefa.possui_fotos === "S" && (
                <button className="botao-ver-imagem"  onClick={() => abrirModalImagem(tarefa.nume_lanca)}>
                  <icones.FaRegImage size={20} />
                </button>
              )}
            </div>
          ),
        }));
    } else {
      dadosFiltrados = [{ mensagem: "Nenhuma tarefa registrada." }];
    }
      
    return (
      <BaseLayout>
        <Tarefa />
        <EditModalTarefas
          show={openEditModal}
          login={loginEditaTarefa}
          nume_lanca={idEditaTarefa}
          codi_pesso={codiPessoaEditaTarefa}
          desc_lanca={descEditaTarefa}
          nome_cliente={nomeClienteEditaTarefa}
          handleClose={handleCloseModal}
        />
        <ViewImageModalTarefa
          id={idTarefa}
          show={openImageModal}
          handleCloseModal={fecharModalImagem}
        />
        <main className="tarefas-content">
          <div className="titulo-pagina">
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <h1 style={{ fontSize: "24px" }}>
                Consulta tarefa Pendente/Finalizada
              </h1>
              <span>
                <p style={{ fontSize: "18px", color: "#0d6efd" }}>
                  {verificaEquipe() ? (
                    <strong>Rodinfo</strong>
                  ) : (
                    <strong>Parceria</strong>
                  )}
                </p>
              </span>
            </div>
            <span>
              <p>
                Usuário:{" "}
                <strong>{localStorage.getItem("suporte_usuario")}</strong>
              </p>
            </span>
          </div>

          <div className="filtros-tarefas">
            <select
              className="input"
              value={selectStatus}
              onChange={(e) => setSelectStatus(e.target.value)}
            >
              <option value="Pendente">Pendente</option>
              <option value="Andamento">Andamento</option>
              <option value="Testando">Testando</option>
              <option value="Finalizada">Finalizada</option>
              <option value="Todas">Todas</option>
            </select>

            <select
              className="input"
              value={responsavel}
              onChange={(e) => setResponsavel(e.target.value)}
            >
              <option value="Todos">Responsável</option>
              <option value="Equipe de de Desenvolvimento Syshorti">
                Equipe de de Desenvolvimento Syshorti
              </option>
              <option value="Adriano">Adriano</option>
              <option value="André">André</option>
              <option value="Bento">Bento</option>
              <option value="Dasniel">Dasniel</option>
              <option value="Didio">Didio</option>
              <option value="Rodimilson">Rodimilson</option>
              <option value="Samuel">Samuel</option>
              <option value="Victor">Victor</option>
            </select>

            <select
              className="input"
              value={clienteSelecionado}
              onChange={handleSelectChange}
            >
              <option>Todos</option>
              {cliente
                .slice()
                .sort((a, b) => a.nome_fanta.localeCompare(b.nome_fanta))
                .map((item, index) => (
                  <option key={index} value={item.nome_fanta}>
                    {item.nome_fanta}
                  </option>
                ))}
            </select>

            <button className="add-task-btn" onClick={openSidebar}>
              <icones.FiPlus size={22} />
            </button>
          </div>

          <section className="lista-tarefas">
            {loading && <LoadingSpinner />}
            {!loading && (
              <DataTable
                columns={columns}
                data={dadosFiltrados}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                noDataComponent={
                  <div>
                    <span>Nenhum dado encontrado.</span>
                  </div>
                }
                responsive
              />
            )}
          </section>
        </main>
      </BaseLayout>
    );
}