import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import Chart from "react-apexcharts";
import DataTable from "react-data-table-component";

import BaseLayout from "../../layout/BaseLayout";

import './styles.css'

const api = axios.create({
    baseURL: "https://www.syshorti.com.br:2053",
    headers: {
        "Content-type": "application/json",
    }
});

export default function Monitoramento (){
    const navigate = useNavigate()

    const [clientes, setClientes] = useState([])
    const [ativosDashboard, setAtivosDashboard] = useState([])
    const [qtdAcessos, setQtdAcessos] = useState([])

    const [adm, setAdm] = useState([])
    const [clienteSelecionado, setClienteSelecionado] = useState('')

    const [acessos, setAcessos] = useState([])
    const [tipoDispo, setTipoDispo] = useState([])

    const [dadosTabela, setDadosTabela] = useState([])
    const [loading, setLoading] = useState(false)
    
    
    useEffect(() => {

        async function carregaClientesDashboard() {
            const response = await api.get('/consulta/log/*');
            const dados = response.data.retorno;
    
            const filtraQtdAcessos = dados.filter(item => !isNaN(parseInt(item.qtde_acesso, 10)));
            
            const somarQtdeAcesso = (data) => {
                const somaAcessos = {};
            
                dados.forEach(item => {
                    const docum = item.nume_docum;
                    const acesso = parseInt(item.qtde_acesso, 10);
            
                    if (somaAcessos[docum]) {
                        somaAcessos[docum] += acesso;
                    } else {
                        somaAcessos[docum] = acesso;
                    }
                });
            
                return Object.values(somaAcessos);
            };
            
            const resultado = somarQtdeAcesso(dados);
        
            const acessosPorDispositivo = filtraQtdAcessos.reduce((acc, item) => {
                const { tipo_dispo, qtde_acesso } = item;
                const qtdAcessosInt = parseInt(qtde_acesso, 10);
                
                if (!isNaN(qtdAcessosInt)) {
                    if (!acc[tipo_dispo]) {
                        acc[tipo_dispo] = 0;
                    }
                    acc[tipo_dispo] += qtdAcessosInt;
                }
                
                return acc;
            }, {});
    
            const tipoDispo = Object.entries(acessosPorDispositivo).map(([dispositivo, qtd_acesso]) => ({
                dispositivo,
                qtd_acesso
            }));

            const adm = dados.filter(item => item.login === 'rodimilson');
    
            setQtdAcessos(resultado);
            setAtivosDashboard([...new Set(dados.map(item => item.nume_docum))]);
            setTipoDispo(tipoDispo);
            setAdm(adm);
        }
        carregaClientesDashboard();
        
    }, []);

    let totalAcessosPorEmpresa = {
        series: [{
            name:  'Total acessos',
            data: qtdAcessos
        }],
        options: {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ativosDashboard.map(item => item),
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }
        },
      };

      let usoPorDispositivo = {  
        series: tipoDispo.map(item => item.qtd_acesso),
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: tipoDispo.map(item => item.dispositivo),
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },      
    };


    useEffect(() => {
        if (clienteSelecionado !== '') {
            carregaUsuarios();
        }
    }, [clienteSelecionado]); 

    async function carregaUsuarios() {
        try {
            const response = await api.get(`/consulta/log/${clienteSelecionado}`);
            const dadosLog = response.data.retorno;
            setDadosTabela(dadosLog);
        } catch (error) {
            console.error('Erro ao carregar dados:', error);
        }
    }


    const colunas = [
        {
            name: 'Empresa',
            selector: row => row.empresa,
        },
        {
            name: 'Usuário',
            selector: row => row.usuario,
        },
        {
            name: 'Data Acesso',
            selector: row => row.ultimo_acesso,
        },
        {
            name: 'Qtd. Acessos',
            selector: row => row.qtd_acessos,
        },
        {
            name: 'Dispositivo',
            selector: row => row.tipo_dispo,
        },
    ];

    let dados = []
    
    if(dadosTabela.length > 0){
        dados = dadosTabela.map((item)=>({
            empresa: item.nume_docum.toUpperCase(),
            usuario: item.login,
            ultimo_acesso: item.ulti_acesso,
            qtd_acessos: item.qtde_acesso,
            tipo_dispo: item.tipo_dispo
        }))
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '60px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', 
                paddingRight: '8px',
                fontWeight: 'bold',
                fontSize: '16px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };


    return (
        <BaseLayout>
            <main>
                <section className='monitoramento-container'>
                    <h1>Monitoramento de uso Syshorti web</h1>
                   
                    <div style={{width: "100%"}}>
                        <a href="https://www.rodinfo.com.br/syshorti" target="_blank" rel="noreferrer">Acessar SyshortiWeb</a>
                    </div>

                    <div className="select-clientes">
                        <select className="input" value={clienteSelecionado} onChange={(e) => setClienteSelecionado(e.target.value)}>
                            <option value="">Selecione um cliente</option>
                            {ativosDashboard.map((item)=> (
                                <option>{item.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <span>* Somente clientes que possuem acesso ao dashboard serão exibidos</span>
                    </div>

                    <div className="monitoramento-graficos-container">    
                        <div className="grafico-monitoramento">
                            <span className="tipo-titulo">Uso por empresa</span>
                            <Chart
                                type="bar"
                                options={totalAcessosPorEmpresa.options}
                                series={totalAcessosPorEmpresa.series}
                                width="100%"
                                height={400}
                            />
                        </div>

                        {clienteSelecionado && (
                            <div className="grafico-monitoramento">
                                <span className="tipo-titulo">Uso por dispositivo</span>
                                <Chart options={usoPorDispositivo.options} series={usoPorDispositivo.series} type="pie" width="100%" />
                            </div>
                        )}
                    </div>

                    
                    {clienteSelecionado && (
                        <div className="tabela-acessos">
                            <h2>Detalhes de acessos</h2>
                            <DataTable
                                columns={colunas}
                                data={dados}
                                customStyles={customStyles}
                            />
                        </div>
                    )}


                </section>
            </main>
        </BaseLayout>
    );
}
