export function getCurrentDate (){
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function horarioValido(){
  const hora = new Date();
  
  const horas = hora.getHours();
  const minutos = hora.getMinutes().toString().padStart(2, '0');;
  const segundos = hora.getSeconds();

  const horario = horas + ':' + minutos + ':' + segundos

  return horario
}

export function dataAtual() {
  const data = new Date();
  return new Date(Date.UTC(data.getUTCFullYear(), data.getUTCMonth(), data.getUTCDate()));
}

export function data_timestamp_imagem(tipo_imagem){
  const date = new Date();

  const ano = date.getFullYear().toString().slice(-2);
  const mes = (date.getMonth() + 1).toString().padStart(2, '0')
  const dia = date.getDate().toString().padStart(2, '0')

  const horas = date.getHours().toString().padStart(2, '0')
  const minutos = date.getMinutes().toString().padStart(2, '0')
  const segundos = date.getSeconds().toString().padStart(2, '0')

  const timestamp = `${tipo_imagem}_${ano}${mes}${dia}${horas}${minutos}${segundos}`;

    return timestamp
}

export function data_dia_primeiro(){
  const data = new Date();

  const dia = '01'
  const mes = (data.getMonth() + 1).toString().padStart(2, '0');
  const ano = data.getFullYear();

  return `${ano}-${mes}-${dia}`
}
