import styles from './styles.module.css'
import React, { useState } from 'react';


const DateInput = ({
  title,
  value,
  onChange,
  disabled = false,
  format = 'yyyy-MM-dd',
  minDate,
  maxDate,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value || '');

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div>
      <label htmlFor="" className={styles.label}>{title}</label>
      <input
        type="date"
        value={inputValue}
        onChange={handleChange}
        disabled={disabled}
        min={minDate}
        max={maxDate}
        className={styles.input}
        {...props}
        />
    </div>
  );
};

export default DateInput;
