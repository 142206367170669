import styles from './styles.module.css'

export const HeaderSubtitle = ({children}) => {
    return <span className={styles.subtitle}>{children}</span>;
}

export const HeaderLeft = ({ children }) => {
  return <div>{children}</div>;
};

export const HeaderRight = ({ children }) => {
  return <div>{children}</div>;
};

export const HeaderTitle = ({children}) =>{
    return <h2>{children}</h2>
}

export const Header = ({children}) => {
    return <div className={styles.header}>{children}</div>;
}


