import { useRef } from 'react'
import  TextArea from '../TextArea';
import { Input } from '../Input';

import { Apple, Factory, FlaskConical, CircleHelp } from 'lucide-react';

import styles from './styles.module.css'

export default function CardCliente({
    codigo,
    chavePessoa, 
    nomeFanta, 
    versao, 
    senhaBd, 
    usuarioWindows,
    senhaWindows,
    idTeamViewer,
    senhaTeamViewer, 
    idAnydesk, 
    senhaAnydesk, 
    observacaoGeral, 
    enderecoVPS, 
    emailBackup,
    senhaBackup,
    tipoSuporte,
    nomePesso,
    preenchimentoAutomatico,
    estadoCliente

    }){

    const inputRef = useRef(null);
    
    function iconeSistema(versao){
        const versao_sistema = versao.toLowerCase();

        if(versao_sistema === 'ceasapr' || versao_sistema === 'ceasasp' || versao_sistema === 'nama' ){
            return <div className={styles.iconContainer}>
                        <Apple size={20} color='#fb923c'/>
                   </div>
        }
        if(versao_sistema === 'jda' || versao_sistema === 'alumiportas'){
            return <div className={styles.iconContainer}>
                        <Factory size={20} color='#737373'/>
                   </div>
        }
        if(versao_sistema === 'biovida'){
            return  <div className={styles.iconContainer}>
                        <FlaskConical size={20} color='#0d6efd'/>
                    </div> 
        }else{
            return <div className={styles.iconContainer}>
                        <CircleHelp size={20} color='#737373'/>
                   </div>
        }
    }

    console.log('CARD', codigo)

    return (
        <details className={styles.details} onClick={preenchimentoAutomatico}>

            <summary className={styles.summary}> 
                <div>{iconeSistema(versao)}</div>
                <span>{codigo} - {nomeFanta} - {nomePesso} - {estadoCliente}</span>                
            </summary>

            <div className={styles.detailsColumn}>
                <section>
                    <div>
                        <label htmlFor="">Versão do Sistema</label>
                        <Input value={versao} isDisabled ref={inputRef}/>
                    </div>
                    <div>
                        <label htmlFor="">Senha do banco de dados</label>
                        <Input value={senhaBd} isDisabled/>
                    </div>
                    <div>
                        <label htmlFor="">Usuário Backup Nuvem</label>
                        <Input value={emailBackup} isDisabled/>
                    </div>    
                    <div>
                        <label htmlFor="">Senha Backup Nuvem</label>
                        <Input value={senhaBackup} isDisabled/>
                    </div> 
                    <div>
                        <label htmlFor="">Outras observações</label>
                        <TextArea value={observacaoGeral} isDisabled/>
                    </div>             
                </section>
                <section>
                    <div className={styles.twoItensRow}>
                        <div>
                            <label htmlFor="">Usuário do Windows</label>
                            <Input value={usuarioWindows} isDisabled/>
                        </div>
                        <div>
                            <label htmlFor="">Senha do Windows</label>
                            <Input value={senhaWindows} isDisabled/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="">Acesso Remoto / VPN</label>
                        <Input value={enderecoVPS} isDisabled/>
                    </div>    
                    <div className={styles.twoItensRow}>
                        <div>
                            <label htmlFor="">ID Team Viewer</label>
                            <Input value={idTeamViewer} isDisabled/>
                        </div> 
                        <div>
                            <label htmlFor="">Senha Team Viewer</label>
                            <Input value={senhaTeamViewer} isDisabled/>
                        </div>  
                    </div>
                    <div className={styles.twoItensRow}>
                        <div>
                            <label htmlFor="">ID Anydesk</label>
                            <Input value={idAnydesk} isDisabled/>
                        </div>  
                        <div>
                            <label htmlFor="">Senha Anydesk</label>
                            <Input value={senhaAnydesk} isDisabled/>
                        </div> 
                    </div>            
                </section>
            </div> 
        </details>
    )
}