import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { getCurrentDate } from '../../../utils/dataAtual'
import { formataData } from '../../../utils/formatadores'

import api from '../../../api/api'

import toast from 'react-hot-toast';

import { Input } from '../../Input';

import CustomButton from '../../../components/ui/Button';
import UploadComponent from '../../../components/UploadComponent'

import styles from './styles.module.css';

export default function FormRegistraChamado({ chave, idSelecionado, tipoSuporte }) {
      const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();

      const [progressBar, setProgressBar] = useState({ started: false, pc: 0 });
      const [imagens, setImagens] = useState([]);
      const [resetImages, setResetImages] = useState(false);
      const [imageDetails, setImagesDetails] = useState([]);
      const [uploadMessage, setUploadMessage] = useState(null);
      const [programa, setPrograma] = useState([]);

      const localAtendimento = [
        { local: "Presencial" },
        { local: "RodInfo" },
        { local: "Home-office" },
        { local: "Infocase Ceasa" },
        { local: "Infocase Matriz" },
      ];

      useEffect(() => {
        if (idSelecionado) {
          setValue("codigo_cliente", idSelecionado);
        }
        setValue("nome_responsavel", localStorage.getItem("suporte_usuario"));
      }, [idSelecionado, setValue]);

      function onSubmit(dados) {
        const form = new FormData();

        form.append("data_chama", formataData(getCurrentDate()));
        form.append("login", dados.nome_responsavel);
        form.append("tipo_pesso", chave);
        form.append("codi_pesso", dados.codigo_cliente);
        form.append("nome_conta", dados.nome_contato);
        form.append("desc_probl", dados.descricao_problema);
        form.append("codi_filia", 1);
        form.append("nome_progr", dados.programa_selecionado.split(" - ")[1]);
        form.append("chav_chama", dados.programa_selecionado.split(" - ")[0]);
        form.append("tipo_supor", tipoSuporte);
        form.append("loca_chama", dados.local_atendimento);

        if (!imagens) {
          form.append("file", null);
        } else {
          for (let i = 0; i < imagens.length; i++) {
            form.append(`file${i + 1}`, imagens[i]);
          }

          if (imagens.length > 0 && progressBar.pc !== 100) {
            setUploadMessage("Anexando imagens, aguarde...");
          }

          setProgressBar((prevState) => ({
            ...prevState,
            started: true,
          }));
        }

        console.log("Dados do Formulário Enviados:");
        for (let [key, value] of form.entries()) {
          console.log(`${key}: ${value}`);
        }

          api.post('/cadastro/chamado2', form, {
              onUploadProgress: (progressEvent) => {
                  setProgressBar(prevState => ({
                      ...prevState,
                      pc: progressEvent.progress * 100
                  }));
              },
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
              }).then((res) => {
              if (res.status === 200) {
                  setResetImages(true);
                  toast.success("Chamado adicionado!");
                  setProgressBar({ started: false, pc: 0 });
                  setImagens([]);
                  setImagesDetails([]);

                  reset({
                    codigo_cliente: "",
                    programa_selecionado: "",
                    nome_contato: "",
                    descricao_problema: "",
                    nome_responsavel: localStorage.getItem("suporte_usuario"),
                  });
              }
          }).catch(err => {
              console.log(err);
          });
      }

      const handleImagesChange = (newImages) => {
        setImagens(newImages);
      };

      useEffect(() => {
        async function fetchProgramas() {
          const response = await api.get(`/consulta/programas`);
          setPrograma(response.data.dados);
        }
        fetchProgramas();
      }, []);
    
    return (
      <div className={styles.formContainer}>
        <h2>Registrar chamado</h2>
        <div>
          <Input
            titulo="Data"
            tipo="date"
            isDisabled={true}
            value={getCurrentDate()}
          />
        </div>
        <div className={styles.formGroup}>
          <div>
            <Input titulo="Tipo" value={chave} tipo="text" isDisabled={true} />
          </div>
          <div>
            <Input
              titulo="Cod. do cliente"
              tipo="number"
              value={idSelecionado || ""}
              {...register("codigo_cliente", { required: true })}
            />
            {errors?.codigo_cliente?.type === "required" && (
              <p className={styles.erro}>* Campo obrigatório</p>
            )}
          </div>
          <div>
            <div>
              <label htmlFor="" className={styles.selectLabel}>
                Módulo
              </label>
              <select
                className={styles.select}
                {...register("programa_selecionado", { required: true })}
              >
                <option value="">Módulo</option>
                {programa.length > 0 ? (
                  programa.map((x, index) => (
                    <option
                      key={index}
                      value={`${x.nome_progr} - ${x.desc_progr}`}
                    >
                      {x.desc_progr} - {x.nome_progr}
                    </option>
                  ))
                ) : (
                  <option disabled>Carregando programas...</option>
                )}
                <option value="Outros - AlumiPortas">AlumiPortas</option>
                <option value="Outros - Indústria">Indústria</option>
                <option value="Outros - Biovida">Biovida</option>
                <option value="Outros - Aplicativo">Aplicativo</option>
                <option value="Outros - Permissões">Permissões</option>
                <option value="Outros - Dúvidas">Dúvidas</option>
                <option value="Outros - Certificado">Instalação certificado</option>
                <option value="Outros - Mapeamento e conexão">
                  Mapeamento e conexão
                </option>
                <option value="Outros - Instalação de impressoras">
                  Impressoras
                </option>
                <option value="Outros - Relatórios">Relatórios</option>
                <option value="Outros - Recibos">Recibos</option>
                <option value="Outros - Outros">Outros</option>
                <option value="Outros - Implantação">Implantação</option>
                <option value="Outros - Terminal">Terminal</option>
              </select>
            </div>

            {errors?.programa_selecionado?.type === "required" && (
              <p className={styles.erro}>* Campo obrigatório</p>
            )}
          </div>
        </div>
        <div>
          <Input
            titulo="Nome do Contato"
            tipo="text"
            {...register("nome_contato", { required: true })}
          />
          {errors?.nome_contato?.type === "required" && (
            <p className={styles.erro}>* Campo obrigatório</p>
          )}
        </div>
        <div>
          <Input
            titulo="Descrição do problema"
            tipo="text"
            {...register("descricao_problema", { required: true })}
          />
          {errors?.descricao_problema?.type === "required" && (
            <p className={styles.erro}>* Campo obrigatório</p>
          )}
        </div>
        <div className={styles.formGroup}>
          <Input
            titulo="Responsável"
            tipo="text"
            disabled
            {...register("nome_responsavel", { required: true })}
          />
          {errors?.nome_responsavel?.type === "required" && (
            <p className={styles.erro}>* Campo obrigatório</p>
          )}

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="" className={styles.selectLabel}>
              Local do Atendimento
            </label>
            <select
              {...register("local_atendimento", { required: true })}
              className={styles.select}
            >
              <option value="">Selecionar local</option>
              {localAtendimento.map((item) => (
                <option>{item.local}</option>
              ))}
            </select>
            {errors?.local_atendimento?.type === "required" && (
              <p className={styles.erro}>* Campo obrigatório</p>
            )}
          </div>
        </div>

        <div>
          <UploadComponent
            type="CH"
            onImagesChange={handleImagesChange}
            resetTrigger={resetImages}
          />
        </div>

        <div className={styles.botaoRegistra}>
          <CustomButton
            texto="Registrar"
            onClick={() => handleSubmit(onSubmit)()}
          />
        </div>
      </div>
    );
}
