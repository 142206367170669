import { useEffect, useState } from "react";

import { formataData, inverterDiaMes } from "../../utils/formatadores";
import icones from "../../assets/icons/icones";

import UploadComponent from '../../components/UploadComponent'
import FlexButton  from '../../components/ui/FlexButton'

import { Dock } from "react-dock";
import api from "../../api/api";

import styles from './styles.module.css'
import toast from "react-hot-toast";


export default function EditModal({show, id, nomeCliente, handleOpen, handleClose, status, atualizaChamados, login, codigoCliente, descricao, nomeContato}){
    
    const [openModal, setOpenModal] = useState(false)
    const [clientes, setClientes] = useState([])
    
    const [idChamado, setIdChamado] = useState('')
    const [codigo, setCodigo] = useState('')
    const [desc, setDesc] = useState('');
    const [contato, setContato] = useState('')


    const [searchTerm, setSearchTerm] = useState('');
    const [clienteSelecionado, setClienteSelecionado] = useState(null)
    const [filteredClients, setFilteredClients] = useState(clientes);

  
    const [error, setError] = useState({
      error: false,
      message: ''
    })

    const [progressBar, setProgressBar] = useState({ started: false, pc: 0 });
    const [imageDetails, setImagesDetails] = useState([])
 
    const [imagens, setImagens] = useState([]);
    const [resetImages, setResetImages] = useState(false);


    useEffect(()=>{
      setIdChamado(id)
      setCodigo(codigoCliente)
      setContato(nomeContato)
      setDesc(descricao)
      setSearchTerm(nomeCliente)
    },[id])
  

    useEffect(()=>{
      async function carregaClientes(){
        const response = await api.get(`/consulta/cliente/*}|${localStorage.getItem("suporte_usuario")}`)
        setClientes(response.data.dados)
      }
      carregaClientes();
    },[])

    async function InformaUrgencia(){
      
      const urgencia = {
        nume_chama: String(id),
        flag_urgen: "S"
      }

      try{
        const response = await api.put('/informa/urgencia', urgencia);
        if(response.status === 200){
          toast.success('Chamado alterado para urgente')
          handleCloseModal()
          atualizaChamados();
        }
      }catch(error){
        console.log(error)
      }
    }

    async function SalvarEdicao(id) {
    
      if (!desc || !contato || !codigo) {
        alert('Todas as informações são necessárias');
        return;
      }
    
      const form = new FormData();
    
      form.append('login', login);
      form.append('nume_chama', idChamado);
      form.append('codi_pesso', codigo);
      form.append('desc_probl', desc);
      form.append('nome_conta', contato);
      form.append('desc_statu', status);
    
      for (let i = 0; i < imagens.length; i++) {
        form.append(`file${i + 1}`, imagens[i]);
      }
    
      setProgressBar((prevState) => {
        return { ...prevState, started: true };
      });
    
      try {
        const response = await api.put('/modifica/chamado', form, {
          onUploadProgress: (progressEvent) => {
            setProgressBar((prevState) => {
              return { ...prevState, pc: progressEvent.progress * 100 };
            });
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        if (response.status === 200) {
          toast.success('Chamado alterado!');
          setProgressBar({ started: false, pc: 0 });
          setImagens([]);
          setImagesDetails([]);
          atualizaChamados();
          handleCloseModal();
          setResetImages(true);
        }
      } catch (error) {
        console.error('Erro ao alterar o chamado:', error);
        toast.error('Erro ao alterar o chamado.');
      }
    }

    function handleSearch(event){
      const term = event.target.value;
      setSearchTerm(term);
    
      const filtered = clientes.filter(cliente =>
        cliente.nome_fanta.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredClients(filtered);

      if(term.length == 0){
        setFilteredClients([])
      }
    };

    function handleSelect(cliente){
      setSearchTerm(cliente.nome_pesso);
      setClienteSelecionado(cliente.nome_pesso)
      setCodigo(cliente.codi_pesso);
      setFilteredClients([]); 
    };

    function resetFields() {
      setCodigo(null);
      setContato('');
      setDesc('');
      setCodigo('');
      setSearchTerm('');
      setClienteSelecionado('');
      setFilteredClients([]);
      setError({ error: false, message: '' });
      setResetImages(true);
    }

    function handleCloseModal() {
      resetFields();
      handleClose();
      setResetImages(true);
      setTimeout(() => setResetImages(false), 0);
    }

    function handleImagesChange(newImages){
      setImagens(newImages); 
    };
      

    return (
        <Dock
          isVisible={show}
          position="right"
          fluid={false}
          size={420}
          onVisibleChange={handleCloseModal}
        >   
          <aside className={styles.editContainer}>

            <div className={styles.titulo}>
                <div>
                  <h2>Editando chamado: {id}</h2>
                  <h3>resolvido por: {login}</h3>
                </div>

                <div style={{marginTop: '10px'}}>
                  <FlexButton 
                    icon={<icones.FiFlag color="#FFF" size={20} />}
                    color="#dc3545"
                    />
                  <label>Urgente</label>
                </div>
            </div>

            <div>
            <div style={{display: "flex", gap: '10px'}}>
              <input type="text" className={styles.input} value={codigo} onChange={handleSearch} disabled placeholder="Código" />
              <input type="text"className={styles.input} value={searchTerm} onChange={handleSearch} placeholder="Buscar cliente..." maxLength={120}/>
              <ul className={styles.dropdown}>
                {filteredClients.map((cliente, index) => (
                  <li
                    key={index}
                    className={styles.dropdownItem}
                    onClick={() => handleSelect(cliente)}
                  >
                   {cliente.codi_pesso} - {cliente.nome_fanta}
                  </li>
                ))}
              </ul>
            </div>

              <div>
                <span>
                  <p className={styles.descProblema}>Alterar contato</p>
                </span>
                <input maxLength={80} value={contato} onChange={(e) => setContato(e.target.value)} className={styles.input} />
              </div>

              <div>
                <span>
                  <p className={styles.descProblema}>Alterar descrição do problema</p>
                </span>
                <textarea maxLength={500} value={desc} className={styles.textarea} onChange={(e)=> setDesc(e.target.value)}/>
              </div>
                            
                <UploadComponent type="CH" onImagesChange={handleImagesChange} resetTrigger={resetImages}/>
              </div>
              
            
              <div>
                <div className={styles.actions}>
                  <button className={styles.saveButton} onClick={() => SalvarEdicao()}>Salvar</button>
                  <button className={styles.closeButton} onClick={handleCloseModal}>Fechar</button>
                </div>
              </div>

          </aside>
        </Dock>
    )
}