import React from 'react';
import styles from './styles.module.css';

export const Input = React.forwardRef(({ titulo, placeholder, isDisabled, tipo, ...rest }, ref) => {
    return (
        <div className={styles.input_container}>
            {titulo && (
                <label className={styles.input_title}>{titulo}</label>
            )}
            <input
                type={tipo}
                className={styles.input}
                placeholder={placeholder}
                disabled={isDisabled}
                ref={ref} 
                {...rest}
            />
        </div>
    );
});
