import { useEffect, useState } from 'react'
import BaseLayout from "../../layout/BaseLayout";
import icones from '../../assets/icons/icones';

import api from '../../api/api';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';


import { verificaEquipe } from '../../utils/validadores';

import { Header, HeaderLeft, HeaderSubtitle, HeaderTitle } from "../../components/PageHeader";
import FormRegistraChamado from '../../components/Forms/FormChamados';
import CardCliente from '../../components/CardCliente';

import './styles.css'

import { Input } from '../../components/Input';
import CustomButton from '../../components/ui/Button';



export default function Clientes() {   
   const [busca, setBusca] = useState('');
   const [clientes, setClientes] = useState([])

   const [chave, setChave] = useState("C")
   const [codigo, setCodigo] = useState("")
   const [tipoSuporte, setTipoSuporte] = useState('')

   const [loading, setLoading] = useState(false)
    
   const [error, setError] = useState({
        error: false,
        message: ''
    })


  async function executaPesquisaCliente(){
    try {
      if(busca.length < 3){
        setError({
          error: true,
          message: "Informe ao menos três letras."
        })
          
          setLoading(false)

          setTimeout(() => {
            setError({
              error: false,
              message: ''
            });
          }, 3000);
              
            return;
        }

        setLoading(true)
        
        const response = await api.get(`/consulta/cliente/${busca}|${localStorage.getItem("suporte_usuario")}`)
          setClientes(response.data.dados)         

        setError({
          error: false,
          message: ""
        })
        
        setLoading(false)
        return;
       } catch (error) {
        
        setError({
          error: true,
          message: "Nenhum cliente encontrado na busca."
        })

        setLoading(false)

        setTimeout(() => {
          setError({
            error: false,
            message: ''
          });
        }, 3000);
        
        setClientes([])
        setLoading(false)
        setBusca('')
      }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            executaPesquisaCliente();
        }
    };

    function limpaPesquisaCliente(){
        setClientes([])
        setBusca('')
    }
    
    const preencheAutomatico = (chave, codigo, tipo_supor) => {
      setChave(chave);
      setCodigo(codigo);
      setTipoSuporte(tipo_supor);
    };

    const TipoParceria = () => {  
      if (verificaEquipe(localStorage.getItem("suporte_usuario"))){
        return <span style={{ color: "#0D6EFD" }}>RODINFO</span>;
      }else{
        return <span style={{ color: "#0D6EFD" }}>PARCERIA</span>;
      }     
    }
    
    return (
      <BaseLayout>
        <main className="customer-content">
          
          <Header>
            <HeaderLeft>
              <HeaderTitle>
                Consultar/Registrar chamados de clientes <TipoParceria />
              </HeaderTitle>
              <HeaderSubtitle>
                <span>Usuário: {localStorage.getItem("suporte_usuario")}</span>
              </HeaderSubtitle>
            </HeaderLeft>
          </Header>

          <section className="actions-container">
            <div className="search">
              <Input
                placeholder="ex: MM3"
                titulo="Digite o cliente desejado"
                onKeyDown={handleKeyDown}
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
              />
              <CustomButton
                texto={<icones.FiSearch size={20} />}
                onClick={executaPesquisaCliente}
              />
              <CustomButton
                texto={<icones.FiTrash2 size={20} />}
                onClick={limpaPesquisaCliente}
              />
            </div>

            {loading && <LoadingSpinner />}
            {error && <span>{error.message}</span>}
          </section>

          {clientes.map((cliente, index) => (
            <CardCliente
              key={index}
              codigo={cliente.codi_pesso}
              chavePessoa={cliente.tipo_pesso}
              nomePesso={cliente.nome_pesso}
              nomeFanta={cliente.nome_fanta}
              estadoCliente={cliente.esta_pesso}
              versao={cliente.nome_siste}
              senhaBd={cliente.senh_bd}
              idTeamViewer={cliente.id___teamv}
              senhaTeamViewer={cliente.senh_teamv}
              idAnydesk={cliente.id___anyde}
              senhaAnydesk={cliente.senh_anyde}
              observacaoGeral={cliente.obse_geral}
              enderecoVPS={cliente.ende_remot}
              usuarioWindows={cliente.usua_windo}
              senhaWindows={cliente.senh_windo}
              emailBackup={cliente.emai_backu}
              senhaBackup={cliente.senh_backu}
              tipoSuporte={cliente.tipo_supor}
              preenchimentoAutomatico={() =>
                preencheAutomatico(
                  cliente.tipo_pesso,
                  cliente.codi_pesso,
                  cliente.tipo_supor
                )
              }
            />
          ))}

          <div className="form-content">
            <FormRegistraChamado
              chave={chave}
              idSelecionado={codigo}
              tipoSuporte={tipoSuporte}
            />
          </div>
        </main>
      </BaseLayout>
    );
}