import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { formataData } from "../../utils/formatadores";
import { getCurrentDate } from "../../utils/dataAtual";

import api from "../../api/api";

import icones from "../../assets/icons/icones";
import BaseLayout from "../../layout/BaseLayout";

import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import EditModal from '../../components/EditModalChamados'

import toast from "react-hot-toast";

import ViewImageModal from "../../components/ViewImageModal";

import './styles.css'
import InputDate from "../../components/InputDate";
import { ModalEncerramentoChamado } from "./components/ModalEncerramentoChamado";

export default function Chamados () {

    const [chamados, setChamados] = useState([]);
    const [dataInicial, setDataInicial] = useState(getCurrentDate());
    const [dataFinal, setDataFinal] = useState(getCurrentDate());
    const [listaClientes, setListaClientes] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clienteSelecionado, setClienteSelecionado] = useState('')
    
    const handleInitialDate = (data) => {
      setDataInicial(data);
    };

    const handleFinallDate = (data) => {
      setDataFinal(data);
    }   

    const [login, setLogin] = useState('Todos')
    const [status, setStatus] = useState('Pendente')

    const [erro, setErro] = useState({
        erro: false,
        message: ""
    });


    // informações para editar o chamado
    const [dataChamado, setDataChamado] = useState(null)
    const [idChamado, setIdChamado] = useState(null)
    const [nomeCliente, setNomeCliente] = useState(null)
    const [statusChamado, setStatusChamado] = useState(null)
    const [atendente, setAtendente] = useState(null)
    const [codigoCliente, setCodigoCliente] = useState(null)
    const [descChamado, setDescChamado] = useState(null)
    const [nomeContato, setNomeContato] = useState(null)

    //modal de imagem do chamado
    const [openImageModal, setOpenImageModal] = useState(false);

    function handleOpenModal(id_chamado, nome_cliente, status_chamado, login, codi_clien, desc_chamado, nome_contato){
      setIdChamado(id_chamado)
      setNomeCliente(nome_cliente)
      setStatusChamado(status_chamado)
      setAtendente(login)
      setCodigoCliente(codi_clien)
      setDescChamado(desc_chamado)
      setNomeContato(nome_contato)
      setOpenModal(true)
    }

    function carregaInformacaoCliente(e) {
      const selectedOption = listaClientes.find(
          (cliente) => cliente.codi_pesso === e.target.value
      );
      
      if (selectedOption) {
          setClienteSelecionado(selectedOption.codi_pesso);
      }else{
          setClienteSelecionado('');
      }
  }

   function handleCloseModal(){
      setIdChamado(null)
      setOpenModal(false)
    }

    useEffect(()=>{
      async function fetchClientes(){
          const response = await api.get(`/consulta/cliente/*|${localStorage.getItem("suporte_usuario")}`);
          setListaClientes(response.data.dados)
      }
      fetchClientes()
    },[])

    async function ConsultarChamados(){
      const form = new FormData;

      form.append('data_inici', dataInicial)
      form.append('data_final', dataFinal)
      form.append('usuario', localStorage.getItem("suporte_usuario"));
      form.append('cliente', clienteSelecionado)

      try{
        setLoading(true)
        const response = await api.post('/consulta/chamado', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setChamados(response.data.dados)
        setLoading(false)
      }catch(error){
        console.log(error)
        setLoading(false)
      }
    }

    const colunasChamado = [
      {
        name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><strong>ID</strong></span>,
        selector: row => row.id,
        width: '80px'
      },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiCalendar />Data</span>,
          selector: row => row.data,
          width: '200px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiUser />Cliente</span>,
          selector: row => row.cliente,
          style: {width: '100px'}
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiUser />Contato</span>,
          selector: row => row.contato,
          width: '120px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiInfo />Problema</span>,
          selector: row => row.problema,
          style: {width: '100px'}
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.RiCustomerService2Fill />Login</span>,
          selector: row => row.login,
          width: '100px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiAlertTriangle />Status</span>,
          selector: row => row.status,
          width: '150px'
        },
        {
          name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiSettings />Ações</span>,
          selector: row => row.acoes,
          width: '150px'
        },
    ];

    const FinalizarTarefa = async (id) => {  
        
        let dados = JSON.stringify({
          LOGIN: localStorage.getItem("suporte_usuario"),
          nume_chama: id,
        });
        
        if (window.confirm("Deseja encerrar o chamado?")) {
          try {
           const response =  await api.put('atualiza/chamado', dados)
            if(response.status === 200){
              ConsultarChamados()
              toast.success('Chamado finalizado com sucesso!')
            }

          } catch (err) {
            toast.error('Ocorreu um erro ao finalizar o chamado.')
            console.error("Erro ao concluir o chamado:", err);
          }
        } else {
          return;
        }
    };

    function ExibeDescricao(contato, problema){
        alert(`Contato: ${contato} \nProblema: ${problema}
          `)
    }
      
    let chamadosFiltrados = []

    if(chamados.length > 0){
        chamadosFiltrados = chamados.filter((chamado)=> {
   
        // Filtro de usuário
        const loginFilter = login === "Todos" || chamado.login.toLowerCase().includes(login.toLowerCase())

        //Filtro de status
        const statusFilter =
        ( status === "Finalizado" && chamado.desc_statu === "Resolvido" ) ||
        ( status === "Pendente" && chamado.desc_statu === "Pendente" ) || status === "Todos";

        return loginFilter && statusFilter 


        }).map((chamado)=>({
          id: chamado.nume_chama,
          data: chamado.data_chama+ ' ' + chamado.data_hora.substring(11, 19),
          cliente: chamado.nome_pesso,
          contato: chamado.nome_conta,
          problema: chamado.desc_probl,
          login: chamado.login,
          urgencia: chamado.flag_urgen,
          status: chamado.desc_statu.toUpperCase(),
          acoes: (
            <div style={{display: "flex", gap:"5px"}}>
                <button className="botao-tabela-detalhes" onClick={() => ExibeDescricao(chamado.nome_conta, chamado.desc_probl)}>
                    <icones.FiEye size={20} />
                </button> 
                <button className="botao-tabela-editar" onClick={() => 
                   handleOpenModal(chamado.nume_chama, chamado.nome_pesso, chamado.desc_statu, chamado.login, chamado.codi_pesso, chamado.desc_probl, chamado.nome_conta)}>
                   <icones.HiOutlinePencilAlt size={20} />
               </button> 
               {chamado.desc_statu === "Pendente" && (
                <button className="botao-tabela-finalizar" onClick={() => FinalizarTarefa(chamado.nume_chama)}>
                   <icones.FaCheck size={20} />
                </button> 
               )}
               {chamado.possui_fotos === "S" && (
                <button className="botao-ver-imagem" onClick={() => abrirModalImagem(chamado.nume_chama)}>
                  <icones.FaRegImage size={20} />
                </button> 
               )}
            </div>
            )
        }))
     }

     const chamadosConditionalRowStyles = [
        {
          when: row => row.status === "RESOLVIDO",
          style: {
            backgroundColor: "#BFFFC8"
          }
        },
        {
          when: row => row.status === "PENDENTE",
          style: {
            backgroundColor: "#FFB3B2"
          }
        },
        {
          when: row => row.urgencia === "S" && row.status === "PENDENTE",
          style: {
            backgroundColor: "#b64949",
            color: "#FFF"
          }
        },
     ];
      
     const customStyles = {
        rows: {
          style: {
            minHeight: '50px',
          },
        },
        headCells: {
          style: {
            paddingLeft: '8px', 
            paddingRight: '8px',
            fontWeight: 'bold',
            fontSize: '16px'
          },
        },
        cells: {
          style: {
            paddingLeft: '8px', 
            paddingRight: '8px',
            fontSize: '20px',
          },
        },
     };

     function verificaEquipe() {
        const usuario = localStorage.getItem("suporte_usuario");
        return usuario === "andre" || usuario === "samuel" || usuario === "bento" || usuario === "rodimilson";
     }

     function abrirModalImagem(id){
        setIdChamado(id)
        setOpenImageModal(true)
     }

     function fecharModalImagem(){
        setOpenImageModal(false)
     }

     useEffect(()=> {
        async function carregarChamados(){
          const form = new FormData;

          form.append('data_inici', getCurrentDate())
          form.append('data_final', getCurrentDate())
          form.append('usuario', localStorage.getItem("suporte_usuario"));
          form.append('cliente', '')
    
          try{
            setLoading(true)
            const response = await api.post('/consulta/chamado', form, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            setChamados(response.data.dados)
            setLoading(false)
          }catch(error){
            console.log(error)
            setLoading(false)
          }        
        }
        carregarChamados()
    },[dataFinal, dataInicial])


    useEffect(() => {
      async function carregarChamados() {
         const form = new FormData();
   
         form.append('data_inici', getCurrentDate());
         form.append('data_final', getCurrentDate());
         form.append('usuario', localStorage.getItem("suporte_usuario"));
         form.append('cliente', '');
   
         try {
           setLoading(true);
           const response = await api.post('/consulta/chamado', form, {
             headers: {
               'Content-Type': 'multipart/form-data',
             },
           });
           setChamados(response.data.dados);
           setLoading(false);
         } catch (error) {
           console.log(error);
           setLoading(false);
         }
       }
   
       carregarChamados();
   
       const intervaloId = setInterval(() => {
         carregarChamados();
       }, 180000);
   
       return () => clearInterval(intervaloId);
     }, []); 
    

    return (
        <BaseLayout>
          {/* <ModalEncerramentoChamado /> */}
            <main className="chamados-content">
               <EditModal 
                show={openModal}
                handleClose={handleCloseModal}
                id={idChamado}
                nomeCliente={nomeCliente}
                dataChamado={dataChamado}
                status={statusChamado}
                atualizaChamados={ConsultarChamados}
                login={atendente}
                codigoCliente={codigoCliente}
                descricao={descChamado}
                nomeContato={nomeContato}
               />

               <ViewImageModal
                  id={idChamado}
                  show={openImageModal}
                  handleCloseModal={fecharModalImagem}
               />
                  
            <div className='titulo-chamados'>
              <div style={{display: 'flex', justifyItems: 'center', alignItems: 'center', gap: '10px'}}>
                <h1>Consulta chamado de cliente</h1>
                 <span>
                   <p style={{fontSize: '18px', color: "#0d6efd"}}>{verificaEquipe() ? (<strong>Rodinfo</strong>) : (<strong>Parceria</strong>)}</p>
                 </span>
              </div>    
            </div>

            <div className='search'>
              <div>
                <div className="desc">
                  <span><strong>Informe a data desejada:</strong></span> 
                  <span>Total de chamados no dia: <strong>{chamados.length}</strong></span>
                  <span>
                    <p>Usuário: <strong>{localStorage.getItem("suporte_usuario")}</strong></p>
                  </span>
                </div>

                <div className='flex-row'>
                  <InputDate title='Data Inicial' value={dataInicial} onChange={(handleInitialDate)}/>
                  <InputDate title='Data Final'   value={dataFinal}   onChange={(handleFinallDate)}/>
                  
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label htmlFor="">Cliente</label>
                      <select className="input" value={clienteSelecionado} onChange={(e) => carregaInformacaoCliente(e)}>
                      <option value="">Todos</option>
                        {listaClientes.map((item, index) => (
                          <option key={index} value={item.codi_pesso}>
                            {item.nome_fanta.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>

                  {verificaEquipe() && (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <label htmlFor="">Colaborador</label>
                      <select className="input" value={login} onChange={(e)=>setLogin(e.target.value)}>
                        <option value="Todos">Todos</option>
                        <option value="Adriano">Adriano</option>
                        <option value="Andre">André</option>
                        <option value="Bento">Bento</option>
                        <option value="Dasniel">Dasniel</option>
                        <option value="Didio">Didio</option>
                        <option value="Rodimilson">Rodimilson</option>
                        <option value="Samuel">Samuel</option>
                      </select>
                    </div>
                  )}

                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <label htmlFor="">Status</label>
                    <select className="input" value={status} onChange={(e)=>setStatus(e.target.value)}>
                      <option value="Todos">Todos</option>
                      <option value="Finalizado">Finalizado</option>
                      <option value="Pendente">Pendente</option>
                    </select>
                  </div>

                  <button className="botao-ok" onClick={ConsultarChamados}>
                    <icones.FiSearch size={24}/>
                  </button>
                </div> 
              </div>
              </div> 

              {erro.erro && (
                <div className='erro-container'>
                  <span>{erro.message}</span>
                </div>
              )}   
              {(chamadosFiltrados.length === 0 && !loading) && (
                <span className='erro-container'>
                  Nenhum chamado encontrado.
                </span>
              )}     
              <section className="tabela-chamados">
                {loading && <LoadingSpinner />}
                 {chamadosFiltrados.length > 0 && (
                    <DataTable
                      columns={colunasChamado}
                      data={chamadosFiltrados} 
                      customStyles={customStyles}
                      conditionalRowStyles={chamadosConditionalRowStyles}
                      noDataComponent={
                        <>
                        </>
                      }
                      striped
                      responsive   
                    />
                 )}
              </section>

            </main>
        </BaseLayout>
    )
}